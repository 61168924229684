(function($){
	$('#file-upload-form').submit(function(e) {

		var formData = new FormData(this);
		$('#videoProgressBar').show();
			var action = $(this).attr('action');
			console.log(action);
			$.ajax({
				type:'POST',
				url: action,
				data:formData,
				xhr: function() {
					var myXhr = $.ajaxSettings.xhr();
					if(myXhr.upload){
						myXhr.upload.addEventListener('progress',progress, false);
					}
					return myXhr;
				},
				cache:false,
				contentType: false,
				processData: false,

				success:function(data){
					$('#videoProgressBar').hide();

					var obj = jQuery.parseJSON(data);
					var status = obj.status;
					var message = obj.message;
					console.log(message);
					if(status == '1'){
						$( ".success" ).show();
						$("#successful-upload").html(message);
						$('#imageuploadform').trigger("reset");

						// $('.fileinput').fileinput('reset')
					} else if (status == '0'){
						$( ".error" ).show();
						$("#error-upload").html(message);
					}
				},

				error: function(data){
					console.log(data);
				}
			});
		

		e.preventDefault();

	});


	function progress(e){

		if(e.lengthComputable){
			var max = e.total;
			var current = e.loaded;

			var Percentage = (current * 100)/max;
			console.log(Percentage);
			$("#videoProgressBar .progress-bar").width(Percentage+'%');


			if(Percentage >= 100)
			{
				// process completed  
			}
		}  
	}

	if(window.location.hash !== "") {
		$('a[href="' + window.location.hash + '"]').click();
	}
	
	if(document.URL.indexOf("sign-up") != -1) {
		$('a[href="#sign-up"]').click();
	}

	var password = $("#signupPassword");
	var confirm_password = $("#signupConfirmPassword");
	function validatePassword(){
		if(password.value != confirm_password.value) {
			confirm_password.setCustomValidity("Passwords Don't Match");
		} else {
			confirm_password.setCustomValidity('');
		}
	}

	password.onchange = validatePassword;
	confirm_password.onkeyup = validatePassword;
	
	$( "a.user-approval" ).on( "click", function() {
		console.log( $( this ).data('uid') );
		var uid = $( this ).data('uid');
		$.ajax({
			type:'POST',
			data: 'uid='+ uid,
			url: '/approve-user',
			dataType: "html",
			cache:false,
			processData: false,

			success:function(data){
			},

			error: function(data){
				console.log(data);
			}
		});


	});
	
	$( "a.user-suspend" ).on( "click", function() {
		console.log( $( this ).data('uid') );
		var uid = $( this ).data('uid');
		$.ajax({
			type:'POST',
			data: 'uid='+ uid,
			url: '/suspend-user',
			dataType: "html",
			cache:false,
			processData: false,

			success:function(data){
			},

			error: function(data){
				console.log(data);
			}
		});


	});
	
	$( "a.list-delete-entry" ).on( "click", function(e) {
		e.preventDefault();
		console.log( $( this ).data('uid') );
		var uid = $( this ).data('uid');
		var list = $( this ).data('list');
		var poop = JSON.stringify({ 
			'uid': uid,
			'list':list
		});
		var tr = $(this).closest('tr'); //here we hold a reference to the clicked tr which will be later used to delete the row

		$.ajax({
			type:'POST',
			data: poop,
			url: '/list-delete-entry',
			contentType: 'application/json',
			cache:false,
			processData: false,

			success:function(data){
				tr.find('td').fadeOut(1000,function(){ 
					tr.remove();                    
				}); 
			},

			error: function(data){
				console.log(data);
			}
		});


	});
	
	function randomPassword(length) {
		var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
		var pass = "";
		for (var x = 0; x < length; x++) {
			var i = Math.floor(Math.random() * chars.length);
			pass += chars.charAt(i);
		}
		return pass;
	}
	
	$( "a.generate-password" ).on( "click", function() {
		var passwordField = $(this).data('field');
		var password = randomPassword(16);
		$("input[name="+passwordField+"]").val(password);
	});
	
	$("#FilUploader").on("change", function () {
		var fileExtension = ['csv'];
		if ($.inArray($(this).val().split('.').pop().toLowerCase(), fileExtension) == -1) {
			alert("Only formats are allowed : "+fileExtension.join(', '));
			$("#FilUploader").val("");
		}
	});
	
//	$('form#admin_add_user').submit(function(e) {
//		// Stop the browser from submitting the form.
//		e.preventDefault();
//		console.log($(this).attr('action'));
//		// Serialize the form data.
//		var formData = $(this).serialize();
//
//		// Submit the form using AJAX.
//		$.ajax({
//			type: 'POST',
//			url: $(this).attr('action'),
//			data: formData
//		})
//			.done(function(response) {
//			// Make sure that the formMessages div has the 'success' class.
//			console.log(response);
//			// Clear the form.
//			$('input[name=username]').val('');
//			$('input[name=email]').val('');
//			$('input[name=company]').val('');
//			$('input[name=password]').val('');
//		})
//			.fail(function(data) {
//			// Make sure that the formMessages div has the 'error' class.
//
//			// Set the message text.
//		});
//
//	});
	
	function validateEmail(email){  
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  
		if(re.test(email)){  
			return true;  
		}else{  
			return false;  
		}  
	}  

	$( "#contact-email" ).on( "blur", function( event ) {
		var email = $(this).val();
		var check = validateEmail(email);
		console.log(check);
		if(check === false){
			alert('Please enter a valid email');
		}
	});
	
	$(window).scroll(function() {
		if ($(document).scrollTop() > 50) {
			$('nav').addClass('shrink');
		} else {
			$('nav').removeClass('shrink');
		}
	});

	
	
})(jQuery); 